<template>
  <div class="Auth">
    <WelcomeComp :isAuthPropComp="isAuthProp" msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import WelcomeComp from '@/components/Welcome.vue'

export default {
  name: 'Welcome',
  props:{
    isAuthProp: {type: Boolean, default:false}
  },

  components: {
    WelcomeComp
  }
}
</script>
