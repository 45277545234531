<template>
  <v-container>
    <v-alert
        dense
        type="success"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageOk"
        @click="closeMessageSendedOk()"
      >
        <strong>{{okSendMessage}}</strong>
      </v-alert>
      <v-alert
        dense
        type="warning"
        color="red"
        style="position:absolute; clear:both; z-index:999; right:0px;"
        v-if="sendedMessageError"
        @click="closeMessageSendedError()"
      >
        <strong>Error: </strong>{{errorSendMessage}}
      </v-alert>

      <v-container>
      
        <v-row align="center"
          justify="center">
        <v-col
            cols="12"
            md="6"
            sm="6"
          >
          <v-card
            class="mx-auto my-12 text-center p-4"
            style="width:95%;"
          >
          <h2 class="mt-4">Seleccionar rango de fechas</h2>
          <date-range-picker
              ref="picker"
              :opens="center"
              :locale-data="{ firstDay: 0, format: 'YYYY-mm-dd' }"
              :minDate="minDate" :maxDate="maxDate"
              :singleDatePicker="singleDatePicker"
              :timePicker="timePicker"
              :timePicker24Hour="timePicker24Hour"
              :showWeekNumbers="showWeekNumbers"
              :showDropdowns="showDropdowns"
              :autoApply="autoApply"
              v-model="dateRange"
              :linkedCalendars="linkedCalendars"
              :disabled="consultCreated"
          >
          <template v-slot:input="picker" style="min-width: 350px;">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
          </template>
          </date-range-picker>
          </v-card>
        </v-col>

        <!--<v-col
            cols="12"
            md="4"
            sm="6"
        >
          <h2 class="mt-4">Filtros</h2>
            <template>
              <v-container
                class="pt-0 flex-center mt-0"
                fluid
              >
                <v-radio-group row v-model="radioGroup">
                  <v-radio
                    v-for="n in radioOptionsTypeMessage"
                    :key="n"
                    :label="`${n}`"
                    :value="n"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>
        </v-col>-->
        <v-col
            cols="12"
            md="6"
            sm="12"
            class="mb-4 text-center"
          >
          <v-btn
            class="mr-4 success"
            @click="obtainReport()"
            :disabled="consultCreated"
          >
            Consultar
          </v-btn>
           
          <v-btn class="mr-4" @click="clear">
            Limpiar
          </v-btn>
        </v-col>
      </v-row>

      </v-container>
    
    <!--<v-row v-if="reportData.length>0">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <h2 class="mt-4 text-center">{{chartDataPeriodReport.name}}</h2>
      <template>
        <Bar
          :chart-options="chartOptionsPeriodReport"
          :chart-data="chartDataPeriodReport"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>
      </v-card>
    </v-row>

    <v-row v-if="reportData.length>0 && radioGroup=='ALL'">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <h2 class="mt-4 text-center">{{chartDataPeriodReportINvOUT.name}}</h2>
      <template>
        <LineChartGenerator
          :chart-data="chartDataPeriodReportINvOUT"
          :char-options="chartOptionsPeriodReportINvOUT"
          :char-id="chartId2"
          :dataset-id-key="datasetIdKey2"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </template>
      </v-card>
    </v-row>-->
    <!-- -->
    <v-row v-if="reportData.length>0">
      <v-card
        class="mx-auto my-6"
        style="width:95%;"
        outlined
      >
      <v-card-title class="m-4">Consultas realizadas ({{reportData.length}})</v-card-title>
      <v-data-table style="width:100%; font-size:11px;"  :headers="headers" :search="search" :items="reportData" item-key="id">
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr
              :class="[item.type=='IN' ? 'light-green lighten-3':'yellow accent-1']"
            >
              <td style="font-size:11px;">{{ item.ip }}</td>
              <td style="font-size:11px;" width="20%">{{ item.genesys_org_id }}</td>
              <td style="font-size:11px;" width="20%">{{ item.connector_public_id }}</td>
              <td style="font-size:11px;">{{ item.counter }}</td>
              <!--<td style="font-size:11px;" :class="[item.status=='Failed' ? 'red lighten-1':'']">
                  {{ item.status }}
              </td>
              <td style="font-size:11px;">{{ item.record }}</td>-->
              <td style="font-size:11px;"></td>
              <td style="font-size:11px;"></td>
              <td style="font-size:11px;">{{ item.created_at }}</td>
            </tr>
        </template>
      </v-data-table>
      </v-card>
    </v-row>
    <!-- -->
  </v-container>
</template>

<script>
import axios from 'axios'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'

/*import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)*/

export default {
    //, Bar, LineChartGenerator
   name:'Reports',
   components:{
     DateRangePicker
   },
   props: {
    chartId2: {
      type: String,
      default: 'line-chart'
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey2: {
      type: String,
      default: 'label'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 120
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    isAuthPropComp: {type: Boolean, default: () => false},
    tokenPropComp: {type: String, default: () =>null},
    orgIdPropComp: {type: String, default: () =>null}
  },
    data() {

        return {
          chartDataPeriodReport: {
            name:'',
            labels: [ 'January', 'February', 'March' ],
            datasets: [ { data: [200, 20, 12] } ]
          },
          chartOptionsPeriodReport: {
            responsive: true
          },

          chartDataPeriodReportINvOUT: {
            labels: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July'
            ],
            datasets: [
              {
                label: 'Data One',
                backgroundColor: '#f87979',
                data: [40, 39, 10, 40, 39, 80, 40]
              },
              {
                label: 'Data Two',
                backgroundColor: '#ffcc00',
                data: [120, 15, 39, 42, 105, 0, 22]
              }
            ]
          },
          chartOptionsPeriodReportINvOUT: {
            responsive: true,
            maintainAspectRatio: false
          },

          
            errorSendMessage:'',
            okSendMessage:'',
            sendedMessageOk:false,
            sendedMessageError:false,
            dateRange:{
              startDate: moment().format('YYYY-MM-DD'),
              endDate: moment().format('YYYY-MM-DD')
            },
            radioGroup: 'ALL',
            radioOptionsTypeMessage:[
              'ALL'
            ],
            headers:[
              {'text': 'IP', 'value':'ip'},
              {'text': 'Org', 'value':'genesys_org_id'},
              {'text': 'Conector', 'value':'connector_public_id'},
              {'text': 'Peticiones', 'value':'counter'},
              {'text': 'Unit', 'value':'price_unit'},
              {'text': 'Precio', 'value':'price'},
              {'text': 'Fecha', 'value':'created_at'},
            ],
            search:'',
            clientId: '',
            picker: null,
            consultCreated:false,
            //secretClient: '',
            clientSecret:'',
            connectorURL:'',
            tokenGC: '',
            token: '',
            userID: '',
            userRoles:null,
            jsonOauthToken: {},
            
            orgUsers:null,
            orgMsgTemplates:null,
            newTemplate:{
              message:''
            },
            reportData:[],
            range: {},
            auth:false,
            authdata:{
              email:'',
              password:''
            },
            idOrgInDB:'',
            urlAPI: this.$urlAPI+'reports.php',
           /* urlAPI2: this.$urlAPI+'genesys_obtainusersorg.php',
            urlAPI3: this.$urlAPI+'smsApp_configs_getMessagesTemplates.php',
            urlAPI4: this.$urlAPI+'smsApp_configs_createNewTemplate.php',*/
            valid:false,
            typeSelected:'',
            theApp:{
            },
            /*authType:['ClientID/SecretClient Auth', 'ESMT Conn URL Connector'],
            authTypeSelected:'ClientID/SecretClient Auth',*/
            sendNewTemplate:false,
            errorConsult: false,
            errorConsultText: '',
            autoTokenGenerate: false,
            initAuth: false
            
            //token: 'Bearer 72e78e65-5f61-3456-91d9-25584da2f434', 
            //urlAPI: this.$urlAPI+'syniverse_simple_message.php',
            //urlAPI2: this.$urlAPI+'syniverse_bulk_message.php',
           
        }
    },
  filters: {
    dateCell (value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date (val) {
      return val ? val.toLocaleString() : ''
    }
  },
    methods:{
        /*onDateRangeChange(range) {
          this.range = range;
        },
        dateFormat (classes, date) {
          if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
          }
          return classes
        },*/
      closeMessageSendedOk(){
        this.sendedMessageOk = false
        this.okSendMessage =''
        console.log('Notification OK close')

      },
      closeMessageSendedError(){
        this.sendedMessageError = false
        this.errorSendMessage =''
        console.log('Notification error close')

        return false
      },

      clear(){
        this.reportData = []
        this.consultCreated = false
      },

        async obtainReport(){
            
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            
            let init = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            let end = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            console.log(init+', '+end)
            
            let url = this.$urlCONNECTOR+'reports.php?id_organization='+this.orgIdPropComp+'&init='+init+'&end='+end+'&type='+this.radioGroup

            this.consultCreated = true

            
            this.okSendMessage= ''
            this.sendedMessageOk= false
            
            this.sendedMessageError= false
            this.errorSendMessage = ''

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        

                        if(response.data.data.error == 'Sin Resultados'){

                          this.sendedMessageError= true
                          this.errorSendMessage = 'No results found'



                          this.consultCreated = false
                          this.reportData = []

                        }else if(response.data.data.data.length!=null && response.data.data.data.length>0){
                          
                            this.reportData = response.data.data.data
                            
                            

                            this.okSendMessage= 'Consult finish'
                            
                            this.sendedMessageOk= true


                        }

                  
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.consultCreated = false
                        this.reportData = []
                        console.error(error)

                        this.sendedMessageError= true
                        this.errorSendMessage = error

                        
                        

                  //this.auth = false

                })
        },

        async obtainUsersOrg(){
            let url = this.urlAPI2+'?theT='+this.tokenGC
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true

            await axios.get(url, {
                    headers:{
                    }
                    }).then(response => {
                        //this.initAuth = false
                        console.log(response)
                        if(response.data.entities != null){
                            this.orgUsers = response.data.entities

                        }else{

                            this.orgUsers = null
                        }
                        
                    }).catch(error => {
                        /*this.initAuth = false
                        this.errorConsult =  true
                        this.errorConsultText = error*/
                        this.orgUsers = null
                        console.error(error)

                  //this.auth = false

                })
        },

        async obtainOrgMessageTemplates(){
            let url = this.urlAPI3+'?id_organization='+this.idOrgInDB
            await axios.post(url, {
                    headers:{
                    }
                    }).then(response => {
                        console.log(response)
                        if(response.data.data.data != null){
                            this.orgMsgTemplates = response.data.data.data
                        }else{
                            this.orgMsgTemplates = null
                        }
                        
                    }).catch(error => {
                        this.orgMsgTemplates = null
                        console.error(error)

                })
        },

        async createNewMessageTemplates(){
            let url = this.urlAPI4
            //this.errorConsult = false
            ///this.errorConsultText =  ''
            //this.initAuth = true
            this.sendNewTemplate = true

            let data = new FormData();
            data.append('id_organization', this.idOrgInDB)
            data.append('message', this.newTemplate.message)

            await axios.post(url, data, {
                    headers:{
                    }
                    })
                    .then(response => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false

                      if(response.data.code == '01'){
                        console.log('New template created')
                        console.log(response)
                        this.obtainOrgMessageTemplates()
                      }else{
                        console.log('Template not created, try later.')
                        console.log(response.data.data.error)
                      }
                      

                    }).catch(error => {
                      this.newTemplate.message = ''
                      this.sendNewTemplate = false
                      console.log('Error server detected, try later.')
                      console.log(error)

                    })
        },

        
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/

      
              
    },
        mounted(){


        }
    
}
</script>
<style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>