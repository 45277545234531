<template>
  <v-app>
    <v-app-bar
      app
      class="bgColorESMT"
      dark
    >
      <div class="d-flex align-center" @click="goToHome()">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo.svg')"
          transition="scale-transition"
          width="110"
        />
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-by-esmt-white.svg')"
          transition="scale-transition"
          width="110"
        />
      </div>

      <v-spacer></v-spacer>

      <!--<v-btn
        target="_blank"
        text
        v-if="this.$route.name=='App' || this.$route.name=='Auth'"
      >
        <span class="mr-2">Docs</span>
        <v-icon>mdi-table</v-icon>
      </v-btn>-->

      <v-btn
        @click="reloadApp()"
        target="_blank"
        text
        v-if="this.$route.name!='Token'"
      >
        <span class="mr-2">Restart</span>
        <v-icon>mdi-redo</v-icon>
      </v-btn>

      <!--<v-btn
        @click="resetApp()"
        target="_blank"
        text
        v-if="this.$route.name=='App'"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>-->

      <v-btn
        @click="reportsApp()"
        target="_blank"
        text
        v-if="isAuth && (this.$route.name=='App' || this.$route.name=='Welcome' || this.$route.name=='NewConnector')"
      >
        <v-icon>mdi-console</v-icon>
      </v-btn>

      <v-btn
        @click="isAuth = false"
        target="_blank"
        text
        v-if="isAuth"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      
    </v-app-bar>

    <v-main>
      <router-view @isAuthTrueEmit="isAuthTrue" :isAuthProp="isAuth" :tokenProp="token" :clientProp="client" :orgIdProp="orgDBID" :clientIDProp="clientID" :clientSecretProp="clientSecret"/>
    </v-main>
    
  </v-app>
</template>

<script>

import axios from 'axios'

export default {
  name: 'App',

  data(){
    return{
      isAuth:false,
      token:'',
      client:null,
      clientID:null,
      clientSecret:null,
      orgDBID:'',
      sessionIP:''
    }
  },

  methods:{
    isAuthTrue(e){
      console.log(e)
      this.isAuth = true
      this.token = e[0].t
      this.client = e[0].client
      this.orgDBID = e[1]
      
    },
    clientDataUpdate(e){
      this.client = e
    },
    tokenUpdate(e){
      this.token = e
    },
    goToHome(){
      this.$router.push({ name: 'Welcome' })
    },

    reportsApp(){
      this.$router.push({ name: 'Reports' })
    },

    reloadApp(){
      window.location.reload()
    },

    async destroySession() {

        let params = new FormData();
        //params.append('id', a)
        params.append('orgDBID', this.orgDBID)
        params.append('ip', this.sessionIP)

        await axios.post(this.$urlCONNECTOR+'destroySession.php', params, {
                    headers:{

                    }
                    }).then(response => {
                        console.log(response)

                    }).catch(error => {
                        console.error(error)

                  //this.auth = false

        })

      },

    execSession(){
      let theAppLocal = JSON.parse(localStorage.getItem('esmtConnectorApp'))

              this.client = theAppLocal.client
              this.token = theAppLocal.t 
              this.orgDBID = theAppLocal.orgDBID
              this.clientID = theAppLocal.ClientID
              this.clientSecret = theAppLocal.ClientSecret
              this.sessionIP = theAppLocal.Ip

              this.isAuth = true
    },

    resetApp(){
      
      this.destroySession()
        
      localStorage.removeItem('esmtConnectorApp')

      this.client=null
      this.clientID=null
      this.clientSecret=null
      this.orgDBID=""
      this.sessionIP=""
      this.token=""

      if(this.$route.name!='Welcome'){
        this.$router.push({ name: 'Welcome' })
      }
      //this.isAuth = false
    }
  },
  watch:{
    isAuth: function(e) {
                if(e==true){
                  this.execSession()  
                }else{
                  this.resetApp()
                }
    }
              
  },
  mounted(){
    if(localStorage.getItem('esmtConnectorApp')==null && localStorage.getItem('esmtConnectorApp')==undefined){
        console.log('No hay sesión iniciada')
                this.isAuth = false
            }else{
              console.log('Hay sesion')
              this.execSession()
            }
  }
};
</script>

<style>
.bgColorESMT{
  background-color:#33383d !important;
  /*background-color:#013c4c !important;*/
}
</style>
