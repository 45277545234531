<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <ReportsComp :tokenPropComp="tokenProp" :isAuthPropComp="isAuthProp" :orgIdPropComp="orgIdProp"/>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import ReportsComp from '@/components/Reports.vue'

export default {
  name: 'Reports',
  props:{
    isAuthProp: {type: Boolean, default:false},
    tokenProp: {type: String, default:null},
    orgIdProp: {type: String, default:null}
  },
  components: {
    ReportsComp
  }
}
</script>
