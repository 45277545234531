import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/Auth.vue'
import NewConnector from '../views/NewConnector.vue'
import Register from '../views/Register.vue'
import App from '../views/Home.vue'
import Reports from '../views/Reports.vue'
//import Token from '../views/Token.vue'

//import Welcome from '../views/Welcome2.vue'
import Welcome from '../views/Welcome.vue'

import PowerBIConfig from '../views/PowerBIConfig.vue'

Vue.use(VueRouter)
/*
,
  {
    path: '/',
    name: 'Token',
    component: Token
  },
*/
const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },{
    path: '/register',
    name: 'Register',
    component: Register
  },{
    path: '/newconnector',
    name: 'NewConnector',
    component: NewConnector
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/powerbicondig',
    name: 'PowerBIConfig',
    component: PowerBIConfig
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/app',
    name: 'App',
    component: App
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
