<template>
<v-container>
    <v-card style="overflow:hidden;">
      <v-card-text text-center>
    <v-row align="stretch">
      <v-col
          cols="12"
          md="6"
          class="deep-orange lighten-3 mb-2"
          v-if="!isAuthPropComp"
        >
        <div @click="goToRegister()" class="w-100 text-center" style="line-height:0px; cursor:pointer;">
          <p>
            <v-icon
              x-large
              style="font-size:6vw;"
            >
              mdi-bank
            </v-icon>
          </p>
          <p class="font-weight-black">Registrar una nueva organización</p>
        </div>
      </v-col>

      <v-col
          cols="12"
          md="6"
          class="deep-orange lighten-3 mb-2"
          v-else
        >
        <div @click="goToNewConnector()" class="w-100 text-center" style="line-height:0px; cursor:pointer;">
          <p>
            <v-icon
              x-large
              style="font-size:6vw;"
            >
              mdi-cable-data
            </v-icon>
          </p>
          <p class="font-weight-black">Crear nuevo conector</p>
        </div>
      </v-col>

      <!--<v-col
          cols="12"
          md="4"
          class="deep-orange lighten-2 mb-2"
          v-if="isAuthPropComp"
        >
        <div @click="goToAuth()" class="w-100 text-center" style="line-height:0px; cursor:pointer">
          <p>
            <v-icon
              x-large
              style="font-size:6vw;"
            >
              mdi-cable-data
            </v-icon>
          </p>
          <p class="font-weight-black m-0 p-0">Activar conector URL</p>
          <p class="m-0 p-0">A múltiples paises/extensiones</p>
        </div>
      </v-col>-->

      <v-col
          cols="12"
          md="6"
          class="deep-orange lighten-4 mb-2"
          v-if="isAuthPropComp"
        >
        <div @click="goToPowerBI()"  class="w-100 text-center" style="line-height:0px; cursor:pointer">
          <p>
            <v-icon
              x-large
              style="font-size:6vw;"
            >
              mdi-tune-vertical
            </v-icon>
          </p>
          <p class="font-weight-black m-0 p-0">Ver conectores disponibles</p>
          <!--<p class="m-0 p-0">A múltiples paises/extensiones</p>-->
        </div>
      </v-col>

      <v-col
          cols="12"
          class="deep-orange lighten-4 mb-2"
          :md="[isAuthPropComp ? 4 : 6]"
          v-else
        >
        <div @click="goToAuth()"  class="w-100 text-center" style="line-height:0px; cursor:pointer">
          <p>
            <v-icon
              x-large
              style="font-size:6vw;"
            >
              mdi-key
            </v-icon>
          </p>
          <p class="font-weight-black m-0 p-0">Login para activar funciones</p>
          <!--<p class="m-0 p-0">A múltiples paises/extensiones</p>-->
        </div>
      </v-col>
      
    </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
    props:{
      isAuthPropComp:{type:Boolean, default:false}
    },
   name:'Welcome',
    data() {
        return {
        }
    },
    methods:{
        goToRegister(){
            this.$router.push({ name: 'Register' })
        },

        goToNewConnector(){
            this.$router.push({ name: 'NewConnector' })
        },

        goToAuth(){
          this.$router.push({ name: 'Auth' })
        },

        goToPowerBI(){
          this.$router.push({ name: 'PowerBIConfig' })
        }
    },
    watch:{
      /*autoTokenGenerate: function(newValue) {
              //zm8lO9Y9QKGKTeS-BoHCKA
                //console.log('detectado cambio en '+newValue)
                if(newValue!=null){
                  if(newValue == true){
                    this.obtainAuthToken()
                  }
                     
                }

      },*/
              
    },
        mounted(){

          

            /*let theAppLocal = null
            this.auth = false
        
            if(localStorage.getItem('esmtAppConnector')==null){
                this.auth = false
            }else{
              theAppLocal = JSON.parse(localStorage.getItem('esmtAppConnector'))

              this.theApp.name = theAppLocal.name
              this.theApp.description = theAppLocal.description
              this.theApp.state = theAppLocal.state

              this.clientId = theAppLocal.client.id
              this.clientSecret = theAppLocal.client.secret
                

              this.autoTokenGenerate = true
            }

            console.log(theAppLocal)*/

        }
    
}
</script>
<style scoped>

</style>